import React from "react";
import react from "../img/react.webp";
import python from "../img/python.webp";
import swift from "../img/swift.webp";
import html from "../img/html.webp";
import css from "../img/css.webp";
import js from "../img/js.webp";
import wordpress from "../img/wordpress.webp";
import java from "../img/java.webp";
import c from "../img/c.webp";
import me from "../img/me.webp";
class About extends React.Component {
  constructor() {
    super();
    const text = window.innerWidth < 768 ? "Above" : "To the left";
    this.state = {
      about_me: [
        {
          id: "first-p-about",
          content:
            "I'm a senior computer engineering student at Villanova University. Being a passionate learner, I've worked in a variety of programming languages and have extensive experience in both software and hardware development."
        },
        {
          id: "second-p-about",
          content:
            "In particular, I specialize in front and backend development as well as mobile app creation. Below are some of the projects that I've worked on. " + text + " are a subset of the technologies that I've worked with."
        }
      ],
    };
  }
  updateDimensions = () => {
    if(window.innerWidth < 768){
      this.setState(
        {
          about_me: [
            {
              id: "first-p-about",
              content:
                "I'm a senior computer engineering student at Villanova University. Being a passionate learner, I've worked in a variety of programming languages and have extensive experience in both software and hardware development."
            },
            {
              id: "second-p-about",
              content:
                "In particular, I specialize in front and backend development as well as mobile app creation. Below are some of the projects that I've worked on. Above are a subset of the technologies that I've worked with."
            }
          ],
        }
      );
    } else {
      this.setState(
        {
          about_me: [
            {
              id: "first-p-about",
              content:
                "I'm a senior computer engineering student at Villanova University. Being a passionate learner, I've worked in a variety of programming languages and have extensive experience in both software and hardware development."
            },
            {
              id: "second-p-about",
              content:
                "In particular, I specialize in front and backend development as well as mobile app creation. Below are some of the projects that I've worked on. To the left are a subset of the technologies that I've worked with."
            }
          ],
        }
      );
    }
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  render() {
    return (
      <section id="about" className="about-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="box-shadow-full">
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div
                        className="col-sm-6 col-md-5"
                        style={{ margin: "0 auto" }}
                      >
                        <div
                          className="about-img"
                          style={{ textAlign: "center" }}
                        >
                          <img
                            className="img-fluid rounded b-shadow-a"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="skill-mf">
                      {/* <p className="title-s">Skill</p> */}
                      {/* {this.state.skills.map(skill => {
                        return (
                          <React.Fragment key={skill.id}>
                            <span>{skill.content}</span>{" "}
                            <span className="pull-right">
                              {skill.porcentage}
                            </span>
                            <div className="progress">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: skill.porcentage }}
                                aria-valuenow={skill.value}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </React.Fragment>
                        );
                      })} */}
                      <div className="row">
                        <div className="col-6 col-sm-4">
                          <div style={{}}>
                            <img src={react} alt="React" className="img-fluid rounded b-shadow-a" />
                          </div>
                          <div className="text-center">React</div>
                        </div>
                        <div className="col-6 col-sm-4">
                          <div style={{}}>
                            <img src={python} alt="Python" className="img-fluid rounded b-shadow-a" />
                          </div>
                          <div className="text-center">Python</div>
                        </div>
                        <div className="col-6 col-sm-4">
                          <div style={{}}>
                            <img src={swift} alt="Swift" className="img-fluid rounded b-shadow-a" />
                          </div>
                          <div className="text-center">Swift</div>
                        </div>
                        <div className="col-6 col-sm-4">
                          <div style={{}}>
                            <img src={html} alt="HTML" className="img-fluid rounded b-shadow-a" />
                          </div>
                          <div className="text-center">HTML 5</div>
                        </div>
                        <div className="col-6 col-sm-4">
                          <div style={{}}>
                            <img src={css} alt="CSS" className="img-fluid rounded b-shadow-a" />
                          </div>
                          <div className="text-center">CSS 3</div>
                        </div>
                        <div className="col-6 col-sm-4">
                          <div style={{}}>
                            <img src={js} alt="JavaScript" className="img-fluid rounded b-shadow-a" />
                          </div>
                          <div className="text-center">JavaScript</div>
                        </div>
                        <div className="col-6 col-sm-4">
                          <div style={{}}>
                            <img src={wordpress} alt="WordPress" className="img-fluid rounded b-shadow-a" />
                          </div>
                          <div className="text-center">WordPress</div>
                        </div>
                        <div className="col-6 col-sm-4">
                          <div style={{}}>
                            <img src={java} alt="Java" className="img-fluid rounded b-shadow-a" />
                          </div>
                          <div className="text-center">Java</div>
                        </div>
                        <div className="col-6 mx-auto col-sm-4">
                          <div style={{}}>
                            <img src={c} alt="C" className="img-fluid rounded b-shadow-a" />
                          </div>
                          <div className="text-center">C & C++</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="about-me pt-4 pt-md-0">
                      <div className="title-box-2">
                        <h1 className="title-left">About Me</h1>
                      </div>
                      {this.state.about_me.map(content => {
                        return (
                          <p className="lead" key={content.id}>
                            {content.content}
                          </p>
                        );
                      })}
                      <div className="d-flex justify-content-center">
                        <img src={me} alt="Me" className="img-fluid rounded b-shadow-a mx-auto" style={{width: '200px'}} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
