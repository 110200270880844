import React, { lazy } from 'react';
import { root } from './helper'

import Navbar from './navbar.jsx';
import Intro from './intro.jsx';
import About from './about.jsx';
import Portfolio from './portfolio.jsx';
import Contact from './contact.jsx';
import BackToTop from './back-top.jsx';
import Preloader from './preloader';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const Admin = lazy(() => import('./admin.jsx')); 
//const Submissions = lazy(() => import('./submissions.jsx'));
const AddUser = lazy(() => import('./add_user.jsx'));
const EditUsers = lazy(() => import('./edit_users.jsx'));
const ResetPassword = lazy(() => import('./reset_password.jsx'));
const EditAccount = lazy(() => import('./edit_account.jsx'));
class App extends React.Component {

    constructor(props) {
        super(props);
        const token = localStorage.getItem("token");
        const refresh = localStorage.getItem("refresh");
        let isAdmin = false;
        if(token !== null){
            isAdmin = this.getIsAdmin(token);
        }
        this.state = {"token": token, "refresh": refresh, "isAdmin": isAdmin};
        this.updateToken = this.updateToken.bind(this);
        this.updateRefresh = this.updateRefresh.bind(this);
        this.fetchAccessToken = this.fetchAccessToken.bind(this);
        this.signOut = this.signOut.bind(this);
        this.getIsAdmin = this.getIsAdmin.bind(this);
    }

    getIsAdmin(token) {
        try {
            const parts = token.split('.');
            if (parts.length !== 3) {
                throw new Error('Token is not a valid JWT');
            }
            const isAdmin = JSON.parse(atob(parts[1]))["admin"] ? JSON.parse(atob(parts[1]))["admin"] : false;
            return isAdmin;
        } catch (error) {
            return false;
        }
    }

    updateToken(token) {
        const isAdmin = this.getIsAdmin(token);
        this.setState(prevState => {return {"token": token, "refresh": prevState.refresh, "isAdmin": isAdmin}});
    }

    updateRefresh(refresh) {
        this.setState(prevState => {return {"token": prevState.token, "refresh": refresh}});
    }

    signOut() {
        this.setState({"token": null, "refresh": null});
    }

    async fetchAccessToken() {
        try {
            const response = await fetch(root + "/new_token", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    refresh: this.state.refresh
                })
            });
            const data = await response.text();
            const parsedData = JSON.parse(data);
            if ("error" in parsedData && response.status === 401) {
                alert("There was an error updating session. Logging out.");
                this.signOut();
                return null; // Return null or appropriate value indicating failure
            } else {
                this.setState({"token": parsedData.token});
                return parsedData.token; // Return the access token value
            }
        } catch (error) {
            alert("There was an error updating session. Logging out.");
            this.signOut();
            return null; // Return null or appropriate value indicating failure
        }
    }

    componentDidUpdate(prevState) {
        if (this.state.token !== prevState.token && this.state.token !== null) {
            //save token to local storage
            localStorage.setItem("token", this.state.token)
        } else if (this.state.token === null) {
            //delete token from cookies
            localStorage.removeItem("token");
        }
        if (this.state.refresh !== prevState.refresh && this.state.refresh !== null) {
            //save refresh token to local storage
            localStorage.setItem("refresh", this.state.refresh);
        } else if (this.state.refresh === null) {
            //delete refresh token from cookies
            localStorage.removeItem("refresh");
        }
    }

    render() {
        return (
            <BrowserRouter>
            <Routes>
                <Route
                path="*"
                element={
                    <React.Fragment>
                    <Navbar />
                    <Intro />
                    <About />
                    <Portfolio />
                    <Contact />
                    <BackToTop />
                    <Preloader />
                    </React.Fragment>
                }
                />
                <Route
                path="/admin"
                element={
                    <React.Suspense fallback={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', fontWeight: 'bold' }}>Loading...</div>}>
                    <Admin 
                    isAdmin={this.getIsAdmin(this.state.token)} 
                    updateToken={this.updateToken} 
                    updateRefresh={this.updateRefresh} 
                    signOut={this.signOut} 
                    fetchToken={this.fetchAccessToken} 
                    token={this.state.token}
                    refresh={this.state.refresh}
                    />
                    </React.Suspense>
                }
                />
                <Route
                path="/add_user"
                element={
                    <React.Suspense fallback={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', fontWeight: 'bold' }}>Loading...</div>}>
                    <AddUser 
                    isAdmin={this.getIsAdmin(this.state.token)} 
                    updateToken={this.updateToken} 
                    updateRefresh={this.updateRefresh} 
                    signOut={this.signOut} 
                    fetchToken={this.fetchAccessToken} 
                    />
                    </React.Suspense>
                }
                />
                <Route
                path="/edit_users"
                element={
                    <React.Suspense fallback={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', fontWeight: 'bold' }}>Loading...</div>}>
                    <EditUsers 
                    isAdmin={this.getIsAdmin(this.state.token)} 
                    updateToken={this.updateToken} 
                    updateRefresh={this.updateRefresh} 
                    signOut={this.signOut} 
                    fetchToken={this.fetchAccessToken}
                    />
                    </React.Suspense>
                }
                />
                <Route
                path="/reset_password"
                element={
                    <React.Suspense fallback={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', fontWeight: 'bold' }}>Loading...</div>}>
                    <ResetPassword  />
                    </React.Suspense>
                }
                />
                <Route
                path="/edit_account"
                element={
                    <React.Suspense fallback={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', fontWeight: 'bold' }}>Loading...</div>}>
                    <EditAccount 
                    isAdmin={this.getIsAdmin(this.state.token)} 
                    updateToken={this.updateToken} 
                    updateRefresh={this.updateRefresh} 
                    signOut={this.signOut} 
                    fetchToken={this.fetchAccessToken}
                    />
                    </React.Suspense>
                }
                />
            </Routes>
            </BrowserRouter>
        );
    }
}

export default App;