export const root = "https://apip.joshbusch.com";
//export const root = "http://localhost:5000";

export const handleError = (error, signOut, fetchToken, func) => {
    if (error === "Invalid token") {
        signOut();
    } else if (error === "Token expired") {
        fetchToken().then(token => {
            if (token !== null) {
                localStorage.setItem("token", token);
                func(token)
                return true
            }
        })
    } else {
        alert("There was an error " + error + ". Please try again later.")
        return false
    }
    return false
}