import React from "react";

import arm from "../img/arm.webp"
import modelsim from "../img/mips_modelsim.webp"
import mips_full from "../img/mips_full.webp"
import control from "../img/control.webp"
import alu from "../img/mips_alu.webp"
import overview from "../img/mips_overview.webp"
import home from "../img/bathroom_home.webp"
import search from "../img/bathroom_search.webp"
import review from "../img/bathroom_reviews.webp"
import bathroom_icon from "../img/bathroom_icon.webp"
import mips_icon from "../img/mips_icon.webp"
import confusion from "../img/confusion_matrix.webp"
import matlab from "../img/matlab_icon.webp"
import music from "../img/music_note.webp"
import capstone_icon from "../img/capstone_icon.webp"
import graph from "../img/hmi_graph.webp"
import board from "../img/board.webp"
import rpi from "../img/rpi.webp"

class Portfolio extends React.Component {
  render() {
    return (
      <section id="work" className="portfolio-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="title-box text-center">
                <h1 className="title-a">Portfolio</h1>
                <p className="subtitle-a">
                  Here's a few hand-picked projects of mine.
                </p>
                <div className="line-mf"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="work-box">
                <a href={home} data-lightbox="gallery-vmarine" data-title="Map Home Screen" >
                  <div className="work-img">
                    <img src={bathroom_icon} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-8">
                        <h2 className="w-title">Bathroom Rating App</h2>
                        <p className="w-subtitle text-default">An app for discovering and rating restrooms available to the public</p>
                        <div className="w-more">
                          <span className="w-ctegory">
                            React Native • Expo • Python • Flask • MySQL • API Development
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="w-like">
                          <span className="ion-ios-plus-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  href={search}
                  data-lightbox="gallery-vmarine"
                  data-title="Location Search Page"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={review}
                  data-lightbox="gallery-vmarine"
                  data-title="Review for Bathroom at LaColombe Coffee"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="work-box">
                <a href={arm} data-lightbox="gallery-aguadeluz" data-title="Robotic arm controlled in this project">
                  <div className="work-img">
                    <img src={capstone_icon} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-8">
                        <h2 className="w-title">Capstone Reactive Prosthetic Arm</h2>
                        <p className="w-subtitle text-default">A program that tracks head movements and EMG signals to move a robotic arm</p>
                        <div className="w-more">
                          <span className="w-ctegory">
                            Arduino • Python • UART • EMG Signal Processing
                          </span>{" "}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="w-like">
                          <span className="ion-ios-plus-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  href={graph}
                  data-lightbox="gallery-aguadeluz"
                  data-title="Graph demonstrating conversion of raw-data velocity to useful position data"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="work-box">
                <a href={modelsim} data-lightbox="gallery-todo" data-title="ModelSim simulation of the MIPS processor" >
                  <div className="work-img">
                    <img src={mips_icon} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-8">
                        <h2 className="w-title">MIPS Processor Implementation</h2>
                        <p className="w-subtitle text-default">A MIPS CPU capable of executing assembly commands</p>
                        <div className="w-more">
                          <span className="w-ctegory">
                            Assembly • VHDL • Quartus
                          </span>
                          {/*} / <span className="w-date">18 Sep. 2018</span>*/}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="w-like">
                          <span className="ion-ios-plus-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  href={mips_full}
                  data-lightbox="gallery-todo"
                  data-title="Full MIPS processor implementation schematic"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={control}
                  data-lightbox="gallery-todo"
                  data-title="Control Unit schematic"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={alu}
                  data-lightbox="gallery-todo"
                  data-title="Arithmetic Logic Unit schematic"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={overview}
                  data-lightbox="gallery-todo"
                  data-title="Overview of the MIPS processor schematic created in this project"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
              </div>
            </div>
            <div className="col-md-4 offset-md-2">
              <div className="work-box">
                <a href={confusion} data-lightbox="gallery-smth" data-title="Confusion Matrix illustrating the 100% accuracy of the algorithm on test data" >
                  <div className="work-img">
                    <img src={matlab} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-8">
                        <h2 className="w-title">MatLab Image Classifier</h2>
                        <p className="w-subtitle text-default">A MatLab algorithm for dynamically classifying images based on arbitrary training data</p>
                        <div className="w-more">
                          <span className="w-ctegory">MatLab • Algorithm Development</span>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="w-like">
                          <span className="ion-ios-plus-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="work-box">
                <a href={board} data-lightbox="gallery-mf" data-title="Matrix board running spotify display" >
                  <div className="work-img">
                    <img src={music} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-8">
                        <h2 className="w-title">Matrix Song Display</h2>
                        <p className="w-subtitle text-default">An implementation of the Spotify API allowing the currently playing song to be displayed on a LED matrix</p>
                        <div className="w-more">
                          <span className="w-ctegory">
                            C++ • Soldering • API Implementation
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="w-like">
                          <span className="ion-ios-plus-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  href={rpi}
                  data-lightbox="gallery-mf"
                  data-title="Raspberry pi running software along with soldered matrix display header"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
